import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NewPasswordComponent } from './public/auth/newpassword/new-password.component';
import { RegisterComponent } from './public/auth/register/registration.component';
import { ResendCodeComponent } from './public/auth/resend/resend.component';
import { ForgotPasswordComponent, VerifyPasswordComponent } from './public/auth/forgot/forgot-password.component';
import { HeaderComponent } from './public/header/header.component';
import { LandingComponent, LogoutComponent } from './secure/landing/landing.component';
import { AppComponent } from './app.component';
import { routing } from './app-routing.module';
import { CognitoUtil } from './service/cognito.service';
import { AwsUtil } from './service/aws.service';
import { UserRegistrationService } from './service/user-registration.service';
import { UserLoginService } from './service/user-login.service';
import { UserParametersService } from './service/user-parameters.service';
import { MaterialModule } from './shared/material.module';
import { RouterModule } from '@angular/router';
import { MyProfileComponent } from './secure/profile/profile.component';
import { OrderByPipe } from './secure/orderby.pipe';
import { JisptComponent, DialogOverview, ProgressBarColor } from './secure/jispt/jispt.component';
import { RegistrationConfirmationComponent } from './public/auth/confirm/confirm-registration.component';
import { MFAComponent } from './public/auth/mfa/mfa.component';
import { LoginComponent } from './public/auth/login/login.component';
import { EC2Service } from './service/ec2.service';
import { CloudWatchLogService } from './service/cw.service';
import { CdkColumnDef } from '@angular/cdk/table';
import { DatePipe } from '@angular/common';
import {MatProgressBarModule} from '@angular/material/progress-bar';

@NgModule({
    declarations: [
        NewPasswordComponent,
        LoginComponent,
        LogoutComponent,
        OrderByPipe,
        RegistrationConfirmationComponent,
        ResendCodeComponent,
        ForgotPasswordComponent,
        VerifyPasswordComponent,
        RegisterComponent,
        MFAComponent,
        HeaderComponent,
        MyProfileComponent,
        LandingComponent,
        AppComponent,
        JisptComponent,
        DialogOverview,
        ProgressBarColor
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpModule,
        routing,
        MaterialModule,
        RouterModule,
        BrowserAnimationsModule,
        MatProgressBarModule
    ],
    providers: [
        CognitoUtil,
        AwsUtil,
        UserRegistrationService,
        UserLoginService,
        UserParametersService,
        EC2Service,
        CloudWatchLogService,
        CdkColumnDef,
        DatePipe
    ],
    bootstrap: [AppComponent],
    entryComponents: [DialogOverview]
})
export class AppModule {
}
