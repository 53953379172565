import * as AWS from 'aws-sdk';
import { Injectable , EventEmitter} from '@angular/core';
import { environment } from '../../environments/environment';
import { Callback } from './cognito.service';
import { Http } from '@angular/http';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable()
export class EC2Service {
    
    constructor(private http: Http) {

        AWS.config.update({
            accessKeyId: environment.access_key,
            secretAccessKey: environment.secret_key,
            region: environment.region
        });
    }
    
    getAllInstances(){
        let instancesList: any[] = [];
        const ec2 = new AWS.EC2();
        return new Promise((resolve, reject) => {
        ec2.describeInstances(function (err, data) {
              if (err){
                  window.location.reload();
                  return console.error(err.message);
              } 
                /*console.log(data);*/
               for (var i in data.Reservations){
                   
                    var ins = data.Reservations[i].Instances[0];
                    var name = null;
                    for(var j in ins.Tags){
                        if(ins.Tags[j].Key === 'Name'){
                              name=ins.Tags[j].Value;
                        }
                    }
                   
                    if(name === null) continue;
                    if(ins.InstanceId === environment.jisptUiInstanceId){
                      instancesList.push({'Id':ins.InstanceId, 'Name':'JISPT-UI','State':ins.State.Name,'LaunchTime':ins.LaunchTime});  
                    }
                    if(ins.InstanceId === environment.jisptBastionInstanceId){
                       instancesList.push({'Id':ins.InstanceId, 'Name':'JISPT-Bastion','State':ins.State.Name,'LaunchTime':ins.LaunchTime});  
                    }
                    if(ins.InstanceId === environment.jisptCplexInstanceId){
                      instancesList.push({'Id':ins.InstanceId, 'Name':'JISPT-Cplex','State':ins.State.Name,'LaunchTime':ins.LaunchTime});  
                    }
                    /*if(name.toLowerCase().includes('jispt') || name.toLowerCase().includes('jboss')){
                        instancesList.push({'Name':name,'State':ins.State.Name,'LaunchTime':ins.LaunchTime});
                    }*/
               }
          //console.log(instancesList);
          resolve(instancesList);
        });
        });
    }

    getInstanceStatus(id) {
        const ec2 = new AWS.EC2();
        const params = {
            InstanceIds: [id]
        };
        return new Promise((resolve, reject) => {
            ec2.describeInstanceStatus(params, function (err, data) {
                if (err) {
                    reject(err);
                } else {
                     /*console.log(data);*/
                    if (data.InstanceStatuses.length === 0) {
                        resolve(false);
                    } else if (data.InstanceStatuses[0].InstanceState.Name === 'pending' || 'running') {
                        resolve(true);
                    }
                    reject(false);
                }
            });
        });
    }

    manageInstance(status,value) {
        const lambda = new AWS.Lambda();
        var ec2 = new AWS.EC2();
        var params;
        if(value == 'ui'){
            params = {
            
                InstanceIds: [environment.jisptUiInstanceId]
            
            };
        }
        if(value == 'bastion'){
            params = {
            
                InstanceIds: [environment.jisptBastionInstanceId]
            
            };
        }
        if(value == 'cplex'){
            params = {
            
                InstanceIds: [environment.jisptCplexInstanceId]
            
            };
        }
        return new Promise((resolve, reject) => {
            if(status == 'start-ec2'){
                ec2.startInstances(params, function(err, data) {
                   if (err) console.log(err, err.stack); // an error occurred
                   else  console.log(data);     
                });
            }
            if(status == 'stop-ec2'){
                   ec2.stopInstances(params, function(err, data) {
                       if (err) console.log(err, err.stack); // an error occurred
                       else  console.log(data);     
                    }); 
            }
        });
    }

    getRDSStatus(id) {
        var rds= new AWS.RDS();
         const params = {
              DBInstanceIdentifier: environment.rdsInstanceName, /* required */
         };
        return new Promise((resolve, reject) => {
            rds.describeDBInstances(params, function (err, data) {
                if (err) {
                    reject(err);
                } else {
                    /*console.log(data);*/
                    if (data.DBInstances.length === 0) {
                        resolve(false);
                    } else if (data.DBInstances[0].DBInstanceStatus) {
                        resolve(data);
                    }
                    reject(false);
                }
            });
        });
    }

    getRDSLastActivity(){
        var rds= new AWS.RDS();
        let rdsData: any[] = [];
         const params = {
              DBInstanceIdentifier: environment.rdsInstanceName, 
         };
         return new Promise((resolve, reject) => {
            rds.describeDBInstances(params, function (err, data) {
                if (err) {
                    reject(err);
                } else {
                    /*console.log(data);*/
                    if (data.DBInstances.length === 0) {
                        resolve(false);
                    } else {
                        rdsData.push({'Name':'JISPT-RDS','State':data.DBInstances[0].DBInstanceStatus,'LaunchTime':data.DBInstances[0].LatestRestorableTime});
                        resolve(rdsData);
                    }
                    reject(false);
                }
            });
        });
    }

    manageRDS(status){

        var rds= new AWS.RDS();
         const params = {
              DBInstanceIdentifier: environment.rdsInstanceName, /* required */
         };
        return new Promise((resolve, reject) => {
           if(status=='start'){
                rds.startDBInstance(params, function (err, data) {
                if (err) {
                    reject(err);
                } else {
                    console.log(data);
                }
            });
           }
           if(status=='stop'){
                rds.stopDBInstance(params, function (err, data) {
                if (err) {
                    reject(err);
                } else {
                    console.log(data);
                }
            });
           }
        });

    }
}
