import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk';
import * as CloudWatchLogs from 'aws-sdk/clients/cloudwatchlogs';
import { Stuff } from '../secure/jispt/jispt.component';
import { environment } from '../../environments/environment';


@Injectable()
export class CloudWatchLogService {
    constructor() {
        AWS.config.update({
            accessKeyId: environment.access_key,
            secretAccessKey: environment.secret_key,
            region: environment.region
        });
    }

    getAWS() {
        return AWS;
    }

    getLogEntries(log_group_name, log_stream_name) {

        const params = {
            logGroupName: log_group_name,
            logStreamNames: [log_stream_name]
        };

        const logsClient = new AWS.CloudWatchLogs({ apiVersion: '2014-03-28' });
        return new Promise(function(resolve, reject) {
            logsClient.filterLogEvents(params, onLogs);
            function onLogs(err, data) {
                if (err) {
                    console.log('CloudWatchLogService: Unable to get the logs', err);
                } else {
                    // console.log('CloudWatchLogService: succeeded.');
                    resolve (data.events);
                }
            }
        });
    }
}
