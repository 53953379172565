import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-mfa',
    templateUrl: './mfa.component.html'
    // styleUrls: ['../auth.component.scss']
})
export class MFAComponent {
    @Input() destination: string;
    @Input() onSubmit: (code: string) => void;

    constructor() {
        console.log('MFAComponent constructor');
    }
}
