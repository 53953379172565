import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {UserRegistrationService} from '../../../service/user-registration.service';
import {UserLoginService} from '../../../service/user-login.service';
import {CognitoCallback} from '../../../service/cognito.service';

export class NewPasswordUser {
    username: string;
    existingPassword: string;
    password: string;
}

@Component({
    selector: 'app-newpassword',
    templateUrl: './new-password.component.html',
    styleUrls: ['../auth.component.scss']
})
export class NewPasswordComponent implements CognitoCallback {
    registrationUser: NewPasswordUser;
    router: Router;
    errorMessage: string;

    constructor(public userRegistration: UserRegistrationService, public userService: UserLoginService, router: Router) {
        this.router = router;
        this.onInit();
    }

    onInit() {
        this.registrationUser = new NewPasswordUser();
        this.errorMessage = null;
    }

    // tslint:disable-next-line:use-life-cycle-interface
    ngOnInit() {
        this.errorMessage = null;
        // console.log('Checking if the user is already authenticated.');
        this.userService.isAuthenticated(this);
    }

    onRegister() {
        console.log(this.registrationUser);
        this.errorMessage = null;
        this.userRegistration.newPassword(this.registrationUser, this);
    }

    cognitoCallback(message: string, result: any) {
        if (message != null) {
            this.errorMessage = message;
            console.log('result: ' + this.errorMessage);
        } else {
            console.log('redirecting');
            this.router.navigate(['/securehome/jispt']);
        }
    }

    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (isLoggedIn) {
            this.router.navigate(['/securehome/jispt']);
        }
    }
}
