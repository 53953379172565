export const environment = {
    production: false,

    access_key: 'AKIA2PTQD4XUQMRZJNTN',
    secret_key: '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',
    region: 'us-east-1',

    ///////////////////////// WITH MFA //////////////////////////

    identityPoolId: 'us-east-1:9dafefb3-96af-4a4d-b21a-eaf77917858d',
    userPoolId: 'us-east-1_3kKRNtkps',
    clientId: '638v1gi162iieis5jso5grikk2',


    ///////////////////////// WITHOUT MFA //////////////////////////

    // identityPoolId: 'us-east-1:d2837854-55da-4f51-a9f2-1fe7f32f5334',
    // userPoolId: 'us-east-1_sdo7ysclH',
    // clientId: '6kq16irj88tjof10esrd74et0c',
    jisptUiInstanceId: 'i-087e6a30d3cab06de',
    jisptCplexInstanceId: 'i-0526704e18d5ee186',
    jisptBastionInstanceId: 'i-023f16f3cfccf5cb0',
    rdsInstanceName:'jisui'
};