import {
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSliderModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatToolbarModule,
    MatButtonToggleModule,
    MatSidenavModule,
    MatTableModule,
    MatTabsModule
} from '@angular/material';
import { MatProgressButtonsModule, MatProgressButtonOptions } from 'mat-progress-buttons';
import { NgModule } from '@angular/core';

@NgModule({
    imports: [
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        MatCardModule,
        MatSliderModule,
        MatProgressBarModule,
        MatAutocompleteModule,
        MatInputModule,
        MatGridListModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatListModule,
        MatDialogModule,
        MatToolbarModule,
        MatButtonToggleModule,
        MatProgressButtonsModule,
        MatSidenavModule,
        MatTableModule,
        MatTabsModule
    ],
    exports: [
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        MatCardModule,
        MatSliderModule,
        MatProgressBarModule,
        MatAutocompleteModule,
        MatInputModule,
        MatGridListModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatListModule,
        MatDialogModule,
        MatToolbarModule,
        MatButtonToggleModule,
        MatProgressButtonsModule,
        MatSidenavModule,
        MatTableModule,
        MatTabsModule
    ],
})

export class MaterialModule {
}
