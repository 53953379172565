import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserLoginService } from '../../service/user-login.service';
import { EC2Service } from '../../service/ec2.service';
import { LoggedInCallback } from '../../service/cognito.service';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['/landing.component.scss']
})
export class LandingComponent implements OnInit, LoggedInCallback {

    constructor(public ec2service: EC2Service, public router: Router, public userService: UserLoginService) {
        this.userService.isAuthenticated(this);
        // console.log('LandingComponent: constructor');
    }

    ngOnInit() { }

    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (!isLoggedIn) {
            this.router.navigate(['/home/login']);
        }
        console.log('tested');
    }
}

@Component({
    selector: 'app-logout',
    template: ''
})
export class LogoutComponent implements LoggedInCallback {

    constructor(public router: Router,
        public userService: UserLoginService) {
        this.userService.isAuthenticated(this);
    }

    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (isLoggedIn) {
            this.userService.logout();
            localStorage.clear();
            this.router.navigate(['/home']);
        }

        this.router.navigate(['/home']);
    }
}
