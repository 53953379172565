import {Component} from '@angular/core';
import {UserLoginService} from '../../service/user-login.service';
import {Callback, CognitoUtil, LoggedInCallback} from '../../service/cognito.service';
import {UserParametersService} from '../../service/user-parameters.service';
import {Router} from '@angular/router';


@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class MyProfileComponent implements LoggedInCallback {

    public parameters: Array<Parameters> = [];
    public cognitoId: String;
    columnsToDisplay = ['name', 'value'];

    constructor(
        public router: Router,
        public userService: UserLoginService,
        public userParams: UserParametersService,
        public cognitoUtil: CognitoUtil
    ) {
        this.userService.isAuthenticated(this);
        console.log('In MyProfileComponent');
    }

    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (!isLoggedIn) {
            this.router.navigate(['/home/login']);
        } else {
            this.userParams.getParameters(new GetParametersCallback(this, this.cognitoUtil));
        }
    }
}

export class Parameters {
    name: string;
    value: string;
}

export class GetParametersCallback implements Callback {

    constructor(public me: MyProfileComponent, public cognitoUtil: CognitoUtil) { }

    callback() { }

    callbackWithParam(result: any) {

        for (let i = 0; i < result.length; i++) {
            const parameter = new Parameters();
            if(result[i].getName()!= "sub" && result[i].getName()!= "email_verified" && result[i].getName()!= "phone_number_verified"){
                parameter.name = result[i].getName();
                parameter.value = result[i].getValue();
                this.me.parameters.push(parameter);
            }
        }
    }
}
