import { RouterModule, Routes } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { HeaderComponent } from './public/header/header.component';
import { LoginComponent } from './public/auth/login/login.component';
import { RegisterComponent } from './public/auth/register/registration.component';
import { ResendCodeComponent } from './public/auth/resend/resend.component';
import { ForgotPasswordComponent, VerifyPasswordComponent } from './public/auth/forgot/forgot-password.component';
import { NewPasswordComponent } from './public/auth/newpassword/new-password.component';
import { LandingComponent, LogoutComponent } from './secure/landing/landing.component';
import { MyProfileComponent } from './secure/profile/profile.component';
import { JisptComponent } from './secure/jispt/jispt.component';
import { RegistrationConfirmationComponent } from './public/auth/confirm/confirm-registration.component';

const homeRoutes: Routes = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        component: HeaderComponent,
        children: [
            { path: 'login', component: LoginComponent },
            { path: 'register', component: RegisterComponent },
            { path: 'confirmRegistration/:username', component: RegistrationConfirmationComponent },
            { path: 'resendCode', component: ResendCodeComponent },
            { path: 'forgotPassword/:email', component: VerifyPasswordComponent },
            { path: 'forgotPassword', component: ForgotPasswordComponent },
            { path: 'newPassword', component: NewPasswordComponent },
            { path: '', component: LoginComponent }
        ]
    },
];

const secureHomeRoutes: Routes = [
    {
        path: '',
        redirectTo: '/securehome/jispt',
        pathMatch: 'full'
    },
    {
        path: 'securehome',
        redirectTo: '/securehome/jispt',
        pathMatch: 'full'
    },
    {
        path: 'securehome',
        component: LandingComponent,
        children: [
            { path: 'logout', component: LogoutComponent },
            { path: 'myprofile', component: MyProfileComponent },
            { path: 'jispt', component: JisptComponent}
            // { path: '', component: MyProfileComponent }
        ]
    }
];

const routes: Routes = [
    {
        path: '',
        children: [
            ...homeRoutes,
            ...secureHomeRoutes,
            {
                path: '',
                component: LoginComponent
            }
        ]
    },
];

export const appRoutingProviders: any[] = [];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes);
